import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import PDFPrintPopupBtn, { iRenderPrintPopBtnFnc } from '../PDFPrintPopupBtn';
import { INCLUDE_LOGO } from '../../dynamicForm/labelPrinting/LabelTypeConstants';
import ModalPage from '../../modalPage/ModalPage';
import PrintService from '../../../services/PrintService';
import { apiErrorToast } from '../../toast/ToastHandler';
import PalletLabelModalBody from './PalletLabelModalBody';
import { handleNullException } from '../../../services/UtilsService';

type iState = {
  isLoading: boolean;
  isConfirming: boolean;
  labelType: string;
  pdfLocalUrl?: string;
};
const initialState: iState = {
  isLoading: false,
  isConfirming: false,
  labelType: INCLUDE_LOGO,
};
type iPalletLabelPrint = {
  jobId: string;
  totalQty: number;
  renderBtn?: iRenderPrintPopBtnFnc;
};
const PalletLabelPrint = ({ jobId, renderBtn, totalQty }: iPalletLabelPrint) => {
  const [isOpen, setIsOpen] = useState(false);
  const [state, setState] = useState(initialState);
  const { control, setValue, errors, handleSubmit, watch } = useForm({});

  const openModal = async () => {
    setIsOpen(true);
  };

  const closeModal = () => setIsOpen(false);
  const closePrint = () => {
    setState({ ...state, pdfLocalUrl: undefined });
    setIsOpen(false);
  };
  //  eslint-disable-next-line
  const onPrint = async (data: any) => {
    setState({ ...state, isConfirming: true });
    try {
      const fileUrl = await PrintService.printPalletLabel(jobId, {
        ...data,
        includeLogo: handleNullException(data, 'includeLogo').toString().toUpperCase() === INCLUDE_LOGO.toUpperCase(),
      });
      setIsOpen(false);
      setState({
        ...state,
        isConfirming: false,
        pdfLocalUrl: fileUrl,
      });
    } catch (error) {
      setState({ ...state, isConfirming: false });
      apiErrorToast(error);
    }
  };

  return (
    <PDFPrintPopupBtn
      title={'Pallet'}
      openModal={openModal}
      isLoading={state.isLoading}
      pdfFileUrl={state.pdfLocalUrl}
      closePrint={closePrint}
      renderBtn={renderBtn}
    >
      {isOpen && (
        <ModalPage
          heading={'Print Pallet Label'}
          onCancel={closeModal}
          isConfirming={state.isConfirming}
          isDisabled={totalQty <= 0 || Object.keys(errors).length > 0}
          confirmBtnName={'Print'}
          onConfirm={handleSubmit(onPrint)}
        >
          <PalletLabelModalBody
            control={control}
            errors={errors}
            onChange={setValue}
            watch={watch}
            defaultQty={totalQty}
          />
        </ModalPage>
      )}
    </PDFPrintPopupBtn>
  );
};

export default PalletLabelPrint;
