import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import iJobAttribute from '../../../types/job/iJobAttribute';
import CoreLabelPrintBody from './CoreLabelPrintBody';
import ModalPage from '../../modalPage/ModalPage';
import PrintService from '../../../services/PrintService';
import { INCLUDE_LOGO } from '../../dynamicForm/labelPrinting/LabelTypeConstants';
import { apiErrorToast } from '../../toast/ToastHandler';
import RollPrintUtils from '../rollLabelPrint/RollPrint.utils';
import EmptyRollBody from '../rollLabelPrint/EmptyRollBody';
import PDFPrintPopupBtn, { iRenderPrintPopBtnFnc } from '../PDFPrintPopupBtn';

type iState = {
  isLoading: boolean;
  isConfirming: boolean;
  labelType: string;
  pdfLocalUrl?: string;
};
const initialState: iState = {
  isLoading: false,
  isConfirming: false,
  labelType: INCLUDE_LOGO,
};

type iCoreLabelPrint = {
  jobId: string;
  jobAttributes: Array<iJobAttribute>;
  productId: string;
  renderBtn?: iRenderPrintPopBtnFnc;
};
const CoreLabelPrint = ({ jobId, jobAttributes, productId, renderBtn }: iCoreLabelPrint) => {
  // when state changes, the extractJobAttribute will re-render, that is a waste
  const { rollNumber } = RollPrintUtils.extractJobAttribute(jobAttributes);
  const [isOpen, setIsOpen] = useState(false);
  const [state, setState] = useState(initialState);
  const { control, setValue, errors, handleSubmit, watch } = useForm({});

  const openModal = async () => {
    setIsOpen(true);
  };

  const closeModal = () => setIsOpen(false);
  const closePrint = () => {
    setState({ ...state, pdfLocalUrl: undefined });
    setIsOpen(false);
  };
  //  eslint-disable-next-line
  const onPrint = async (data: any) => {
    setState({ ...state, isConfirming: true });
    try {
      const fileUrl = await PrintService.printCoreLabel(jobId, {
        ...data,
        productId,
      });
      setIsOpen(false);
      setState({
        ...state,
        isConfirming: false,
        pdfLocalUrl: fileUrl,
      });
    } catch (error) {
      setState({ ...state, isConfirming: false });
      apiErrorToast(error);
    }
  };

  return (
    <PDFPrintPopupBtn
      title={'Core'}
      openModal={openModal}
      isLoading={state.isLoading}
      pdfFileUrl={state.pdfLocalUrl}
      closePrint={closePrint}
      renderBtn={renderBtn}
    >
      {isOpen && (
        <ModalPage
          heading={'Print Core Label'}
          onCancel={closeModal}
          isConfirming={state.isConfirming}
          isDisabled={rollNumber === 0 || Object.keys(errors).length > 0}
          confirmBtnName={'Print'}
          onConfirm={handleSubmit(onPrint)}
        >
          {rollNumber > 0 ? (
            <CoreLabelPrintBody
              control={control}
              errors={errors}
              onChange={setValue}
              watch={watch}
              defaultValues={{
                rollNumber,
              }}
            />
          ) : (
            <EmptyRollBody />
          )}
        </ModalPage>
      )}
    </PDFPrintPopupBtn>
  );
};

export default CoreLabelPrint;
