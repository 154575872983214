import React from 'react';
import { Control, DeepMap, FieldError } from 'react-hook-form';
import InputWithController from '../../hookForms/InputWithController';
import { SpaceVerticalWrapper } from '../../styles/styles';
import SelectWithController from '../../hookForms/SelectWithController';
import { INCLUDE_LOGO_OPTION } from '../rollLabelPrint/RollPrint.constants';

const CoreLabelPrintBody = ({
  control,
  onChange,
  errors,
  defaultQty,
}: {
  //  eslint-disable-next-line
  control: Control<Record<string, any>>;
  //    eslint-disable-next-line
  onChange: (name: string, value: any, config?: Object) => void;
  //    eslint-disable-next-line
  errors: DeepMap<Record<string, any>, FieldError>;
  // eslint-disable-next-line
  watch: (names?: string | string[]) => any;
  defaultQty: number;
}) => {
  return (
    <div>
      <SpaceVerticalWrapper className={'x-small'} />
      <InputWithController
        name={'totalQty'}
        label={'Quantity'}
        defaultValue={defaultQty}
        control={control}
        onChange={onChange}
        errors={errors}
        isNumeric
        testId={'print-pallet-total-qty'}
      />
      <SelectWithController
        name={'includeLogo'}
        label={'Print With Logo or not'}
        control={control}
        options={INCLUDE_LOGO_OPTION}
        defaultValue={INCLUDE_LOGO_OPTION[0].value}
        onChange={onChange}
        errors={errors}
        testId={'print-pallet-system-logo'}
      />
    </div>
  );
};

export default CoreLabelPrintBody;
