import React from 'react';
import PrintBtn from '../buttons/PrintBtn';
import PDFPrintPopup from '../PDFPrintPopup';

export type iRenderPrintPopBtnFnc = (props: {
  title: string;
  openModal: () => void;
  isLoading?: boolean;
}) => React.ReactNode;

export type iPDFPrintPopupBtn = {
  children: React.ReactNode;
  openModal: () => void;
  closePrint: () => void;
  isLoading?: boolean;
  pdfFileUrl?: string;
  title: string;
  renderBtn?: iRenderPrintPopBtnFnc;
};
const PDFPrintPopupBtn = ({
  title,
  children,
  closePrint,
  openModal,
  isLoading,
  pdfFileUrl,
  renderBtn,
}: iPDFPrintPopupBtn) => {
  const getPrintBtn = () => {
    if (renderBtn) {
      return renderBtn({ title, openModal, isLoading });
    }
    return <PrintBtn title={title} onClick={openModal} isLoading={isLoading} />;
  };
  return (
    <>
      {getPrintBtn()}
      {children}
      <PDFPrintPopup pdfFileUrl={pdfFileUrl} onClose={closePrint} />
    </>
  );
};

export default PDFPrintPopupBtn;
